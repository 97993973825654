<template>
  <b-container class="p-3x" box>
    <b-overlay :show="loadPage" opacity="0.5" no-wrap></b-overlay>
    <b-card body-class="d-flex justify-content-center pt-5 pb-5" class="bs-br">
      <b-container>
        <b-row align-h="center">
          <b-col cols="12" md="8" lg="8" xl="6">
            <b-form @submit.prevent="addRole" class="form-add-role w-100">
              <b-form-group label="Role name *" label-for="input-name">
                <b-form-input id="input-name" trim placeholder="Enter role name"
                  :class="{'is-invalid': $v.form.name.$error || errorRole}"
                  v-model.trim="$v.form.name.$model"></b-form-input>
                <span role="alert" class="invalid-feedback">
                  <strong v-if="!$v.form.name.required">The role name is required</strong>
                  <strong v-if="!$v.form.name.minLength">The role name must have at least {{$v.form.name.$params.minLength.min}} letters</strong>
                  <strong v-if="!$v.form.name.maxLength">The role name must be less than {{$v.form.name.$params.maxLength.max}} letters</strong>
                  <strong v-if="errorRole && ($v.form.name.required & $v.form.name.minLength && $v.form.name.maxLength)">Role name already exists</strong>
                </span>
              </b-form-group>
              <div class="d-flex justify-content-end mt-2">
                <b-button type="submit" variant="primary">Save Role</b-button>
                <b-button type="reset" @click="$router.push({name: 'permissions.index'})" variant="secondary">Cancel</b-button>
              </div>
            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </b-card>
  </b-container>
</template>
<script>
import { required, email, maxLength, minLength } from 'vuelidate/lib/validators';
export default {
    name: 'add_role',

    data() {
        return {
            form: {
                name: null,
            },
            loadPage: false,
            errorRole: false
        };
    },
    validations: {
        form: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(60)
            },
        },
    },

    mounted() {

    },

    methods: {
        addRole(){
            this.$v.form.$reset();
            this.$v.form.$touch();
            if (!this.$v.form.$invalid) {
                this.errorRole = false;
                this.loadPage = true;
                this.$http.post('role', this.form, { headers: {"Authorization": "Bearer "+this.$session.get('jwt') } }).then((response) => {
                    if (response.status == 200) {
                        this.$notify({ group: 'notifications_app', type: 'success', text: response.body.message });
                        this.$router.push({name: 'permissions.index'})
                    }
                    this.loadPage = false;
                }, (error) => {
                    if (error.status == 500) {
                        this.$notify({ group: 'notifications_app', type: 'error', text: 'An error has occurred, please try again.', duration: 10000 });
                    }
                    if (error.status == 400) {
                        if (error.body.response == 'role_exists') {
                            this.errorRole = true;
                        }
                    }
                    this.loadPage = false;
                })
            }
        }
    },
};
</script>
